import React from "react";
import PageWrapper from "../components/PageWrapper";
import Commit from "../sections/common/Cta";
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/meta-images.jpg'
const Demo = () => {
  return (  
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          footerStyle: "digma",
          version:"images",
        }}>
        <SEO
          title="Digma Interactive Images - Bring your images to life"
          description="Make your images interactive by adding music, video, product carousels, contact forms and more to any image using our plethora of plugins."
          image={metaImg}
        /> 
        <div className="container">
          <div class="digma-image" data-imageid="08132629-EC56-49BC-8228-CB60982B86C5"><img className="w-100" alt="Crystal Palace" src="https://digmacustomers.blob.core.windows.net/2505e4ab-0b83-4ccb-8ff9-720611285935/4457655e-9b19-40d9-aeec-9c53185d6aee.jpg" /></div>
        </div>
        <div className="mt-20" style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
      </PageWrapper>
    </>
  );
};
export default Demo;
